@font-face {
  font-family: Lato;
  src: url('../../Assets/Fonts/Lato-Regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: LatoB;
  src: url('../../Assets/Fonts/Lato-Bold.ttf');
  font-display: swap;
}
.container {
  width: 100%;
  height: 100%;
}
.row12 {
  min-height: 150px;

  /* display: flex; */
  flex-direction: column;
  margin-top: 130px;
  align-self: center;
  flex-wrap: wrap;
  align-content: space-between;
}

.msgBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d8d8d8;
  padding: 20px 10px;
  color: #7c7c7c;
  background-color: white;
  border-radius: 6px;
  justify-content: center;
  text-align: center;
}

.searchDiv {
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  width: 95%;
  padding-left: 10px;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.23px;
  color: #404a57;
  font-family: LatoB;
}

.searchWrap {
  width: 100%;
  display: flex;
  justify-content: center;
}

.mainContent {
  min-height: 75vh;
}

@media (max-width: 576px) {
  .msgBoxCont {
    height: 100%;
    width: 100%;
    padding: 20px;
    align-self: center;
    padding-top: 40px;
    align-items: center;
    justify-content: center;
  }
  .empImg {
    position: relative;
    flex: 1;
    height: 27%;
    width: 27%;
    cursor: pointer;
    min-width: 120px;
    min-height: 120px;
    max-width: 120px;
    max-height: 120px;
    margin-left: 40px;
    margin-top: 20px;
  }
  .spe {
    margin-left: 25px;
    font-size: 17px;
  }
}
@media (min-width: 576px) {
  .msgBoxCont {
    height: 100%;
    width: 60%;
    padding: 20px;
    align-self: center;
    padding-top: 40px;
    margin-left: 17%;
    align-items: center;
    justify-content: center;
  }
  .empImg {
    position: relative;
    flex: 1;
    height: 30%;
    width: 30%;
    cursor: pointer;
    min-width: 150px;
    min-height: 150px;
    max-width: 150px;
    max-height: 150px;
    margin-left: 40px;
    margin-top: 20px;
  }
  .spe {
    margin-left: 50px;
    font-size: 17px;
  }
}

.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  backdrop-filter: blur(3px);
}

.spinnerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  background-color: white;
  padding: 24px;
  border-radius: 20px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.096);
  border: solid 1px #416ce1;
}

.loadingText {
  color: #404a57;
  font-family: ssp;
  font-size: 14px;
  font-weight: 600;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.empImg {
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.empImg:not(.disabled):hover {
  transform: translateY(-2px);
}