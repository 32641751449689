.graphOuter {
  width: 100%;
  height: 90%;
}

@media (max-width: 1024px) {
  .graphOuter {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 768px) {
  .graphOuter {
    width: 120%;
    height: 120%;
  }
}

@media (max-width: 576px) {
  .graphOuter {
    width: 110%;
    height: 110%;
  }
}
