.gapper {
  margin-top: 10vh;
}
.main {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}
.img {
  width: 45vw;
}
.philoH1 {
  font-size: 40px;
}
.philoP {
  font-size: 18px;
}
.philoH2 {
  font-size: 34px;
  margin-bottom: 16px !important;
  margin-top: 5px;
}

.faqSection {
  display: flex;
  flex-direction: row;
  padding: 2em;
  background-color: #f3f3f3;
  height: auto;
}

.textContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 7vw;
  flex: 1;
}

.faqLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.faqRight {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.faqDesc {
  font-size: 20px;
}

.faqLeftText {
  width: 30vw;
}

.AccordionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30vw;
}

.feedback {
  padding-top: 30px;
  padding-bottom: 30px;
}

.button-div {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: violet;
  height: 10vh;
}

@media (max-width: 575px) {
  .main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin: 0 auto;
    height: 77vh;
  }
  .img {
    margin: 0 auto;
    width: 100%;
    display: block;
    margin-bottom: 22px;
    margin-top: 18px;
    float: none;
  }
  .ptagP {
    width: 92%;
    margin-left: 9px;
  }
  .philoH1 {
    font-size: 27px;
    text-align: center;
  }
  .philoH2 {
    font-size: 27px;
    margin-bottom: 16px !important;
  }
  .philoP {
    font-size: 14px;
    text-align: center;
    width: 100%;
  }
  .faqSection {
    display: flex;
    flex-direction: column;
    padding: 2em;
    background-color: #f3f3f3;
    height: auto;
  }
  .faqDesc {
    font-size: 14px;
  }
  .faqHeader {
    font-size: 27px;
  }
  .faqLeftText {
    width: 100%;
    text-align: center;
    padding: 10px;
  }
  .AccordionContainer {
    width: 90vw;
  }
  .faqRight {
    justify-content: center;
  }
  .textContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 0;
    flex: 1;
    padding: 10px;
  }
  .feedbackHeader {
    font-size: 27px;
  }
  .feedbackDesc {
    font-size: 14px;
  }
  .label {
    font-size: 14px;
  }
  .input {
    font-size: 14px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .img {
    margin: 0 auto;
    width: 500px;
    display: block;
    margin-bottom: 22px;
    margin-top: 18px;
    float: none;
  }
  .philoH1 {
    font-size: 35px;
    text-align: center;
  }
  .philoH2 {
    font-size: 30px;
    margin-bottom: 16px !important;
  }
  .philoP {
    font-size: 17px;
    text-align: center;
  }
  .faqSection {
    display: flex;
    flex-direction: column;
    padding: 2em;
    background-color: #f3f3f3;
    height: auto;
  }
  .faqLeft {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .faqDesc {
    font-size: 18px;
  }
  .faqHeader {
    font-size: 30px;
  }
  .faqLeftText {
    width: 100%;
    text-align: center;
  }
  .AccordionContainer {
    width: 80vw;
  }
  .faqRight {
    justify-content: center;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1122px) {
  .img {
    width: 585px;
  }
  .AccordionContainer {
    width: 50vw;
  }
  .faqRight {
    padding-left: 20px;
  }
  .faqLeftText {
    width: 100%;
    text-align: center;
  }
  .faqHeader {
    font-size: 25px;
  }
  .faqDesc {
    font-size: 15px;
  }
}
