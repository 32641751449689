@font-face {
  font-family: LatoBold;
  src: url('../../Assets/Fonts/Lato-Bold.ttf');
}

.row2 {
  width: 100%;
  display: grid;
  grid-template-rows: auto minmax(500px, 1fr) auto;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: 
    'title title title' 
    'graphCont graphCont future' 
    'editA editA future';
  gap: 20px;
  padding: 20px;
  min-height: 100vh;
}

.title {
  grid-area: title;
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  padding: 10px 0 0 20px;
  font-family: LatoBold;
  gap: 10px;
}

.graphCont {
  grid-area: graphCont;
  min-height: 500px;
}

.graphCard {
  box-shadow: 9px 7px 15px rgba(0,0,0,0.05) !important;
  border: 2px solid #ededed !important;
  border-radius: 10px !important;
  display: flex !important;
  flex-direction: column !important;
}

.upper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.graph1, .graph2 {
  flex: 1;
  min-width: 300px;
}

.bottomText {
  padding: 20px;
  display: flex;
  justify-content: center;
}

.editA {
  grid-area: editA;
}

.editCard {
  background: white;
  border-radius: 10px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.future {
  grid-area: future;
  background: white;
  border-radius: 10px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.exclaim {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

/* Mobile Styles */
.smallscreen {
  grid-area: smallscreen;
  padding: 10px;
}

.futureParent {
  width: 100%;
  display: grid;
  place-items: center;
  padding: 20px;
}

.smallFuture {
  width: 95%;
  background: white;
  border-radius: 10px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px 0;
}

.smallFutureHeading h4 {
  margin: 0;
  padding: 10px;
  font-size: 1.2rem;
}

@media (max-width: 1500px) {
  .row2 {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-template-areas: 
      'title' 
      'graphCont' 
      'editA' 
      'future';
  }
}

@media (max-width: 1280px) {
  .row2 {
    grid-template-rows: auto auto;
    grid-template-areas: 
      'smallscreen' 
      'futureParent';
    gap: 40px;
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .upper {
    flex-direction: column;
    padding: 10px;
  }
  
  .graph1, .graph2 {
    min-width: 100%;
  }
  
  .title {
    font-size: 1.5rem;
    padding-left: 10px;
  }
  
  .smallFuture {
    width: 100%;
    margin: 10px 0;
  }
}

@media (max-width: 480px) {
  .graphCard {
    box-shadow: none !important;
    border: none !important;
  }
  
  .editCard, .future {
    box-shadow: none;
    border-radius: 0;
  }
}