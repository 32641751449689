.container {
  width: 480px;
  padding: 34px;
  background: #FFFFFF;
  border-radius: 24px;
  font-family: 'Inter', sans-serif;
  position: relative;
  max-width: 100%;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.title {
  font-size: 28px;
  font-weight: 500;
  color: #1A1A1A;
  margin: 0;
}

.closeButton {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  transition: opacity 0.2s;
}

.closeButton:hover {
  opacity: 0.7;
}

.closeButton:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(67, 97, 238, 0.3);
  border-radius: 4px;
}

.closeIcon {
  width: 24px;
  height: 24px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.label {
  font-size: 14px;
  color: #666666;
  display: flex;
  align-items: center;
  gap: 4px;
}

.required {
  color: #FF4D4F;
}

.input {
  height: 48px;
  padding: 0 16px;
  border: 1px solid #D1D5DB;
  border-radius: 8px;
  font-size: 16px;
  width: 100%;
  transition: all 0.2s;
}

.input:focus {
  outline: none;
  border-color: #4361EE;
  box-shadow: 0 0 0 2px rgba(67, 97, 238, 0.1);
}

.input:disabled {
  background-color: #F3F4F6;
  cursor: not-allowed;
}

.input::placeholder {
  color: #666666;
}

.emailButton {
  height: 48px;
  background: #4361EE;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emailButton:hover:not(:disabled) {
  background: #3651DE;
}

.emailButton:disabled {
  background: #A5B4FC;
  cursor: not-allowed;
}

.divider {
  position: relative;
  text-align: center;
  margin: 16px 0;
}

.divider::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  background: #E5E7EB;
}

.dividerText {
  position: relative;
  background: white;
  padding: 0 16px;
  color: #666666;
  font-size: 14px;
}

.socialButtons {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.socialButton {
  height: 48px;
  background: white;
  border: 1px solid #D1D5DB;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  font-size: 16px;
  color: #666666;
  cursor: pointer;
  transition: all 0.2s;
  width: 100%;
}

.socialButton:hover:not(:disabled) {
  background: #F9FAFB;
  border-color: #9CA3AF;
}

.socialButton:disabled {
  background: #F3F4F6;
  cursor: not-allowed;
  color: #9CA3AF;
}

.socialIcon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
}

/* Responsive Styles */
@media (max-width: 640px) {
  .container {
    width: 100%;
    padding: 24px 20px;
    border-radius: 0;
    min-height: 100vh;
  }
  
  .title {
    font-size: 24px;
  }
  
  .emailButton,
  .socialButton {
    height: 44px;
  }

  .input {
    height: 44px;
  }
}

/* Medium screens */
@media (min-width: 641px) and (max-width: 1024px) {
  .container {
    width: 90%;
    max-width: 480px;
  }
}

/* Ensure container doesn't get too wide on large screens */
@media (min-width: 1025px) {
  .container {
    width: 480px;
  }
}

.inputError {
  border-color: #FF4D4F !important;
}

.inputError:focus {
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.1) !important;
}

.errorMessage {
  color: #FF4D4F;
  font-size: 12px;
  margin-top: 4px;
}

/* Add these to your existing socialButton styles */
.socialButton:focus {
  outline: none;
  border-color: #4361EE;
  box-shadow: 0 0 0 2px rgba(67, 97, 238, 0.1);
}

.loginPrompt {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 4px;
}

.loginLink {
  background: none;
  border: none;
  padding: 0;
  color: #4361EE;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
}

.loginLink:hover {
  color: #2D3A8C;
}

.errorMessage {
  color: #FF4D4F;
  font-size: 12px;
}