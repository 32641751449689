@font-face {
  font-family: LatoB;
  src: url("../../../Assets/Fonts/Lato-Bold.ttf");
  font-display: swap;
}

.root {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 700px 400px 600px;
  grid-template-areas: "row1 row1" "graphCont graphCont" "slider slider" "khaliGraph1 khaliGraph2";
  padding: 15px;
  gap: 10px;
}

/* For the Check */
.exclaim {
  width: 30px;
  height: 30px;
  margin: 10px;
}

.title {
  grid-area: title;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 23px;
  padding: 10px 0px 0px 20px;
  font-family: LatoBold;
}

.row1 {
  height: 500px;
  grid-area: row1;

  display: grid;
  place-items: center;
  grid-template-columns: 5fr 8fr;
  grid-template-rows: 1fr;
  grid-template-areas: "fees table";

  grid-gap: 10px;
  padding: 10px 30px;
}

.feesSaved {
  grid-area: fees;
  height: 100%;
  width: 100%;
}

.employerTable {
  grid-area: table;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
}

.picker {
  flex: 1;

  display: flex;
  flex-direction: row;

  display: none;
  padding-bottom: 20px;
}

.tp {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainTable {
  flex: 10;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  background: white;
}

.table {
  flex: 12;
}

.seeMore {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.seeMoreCont {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
  position: relative;
}

.graphCont {
  grid-area: graphCont;
  display: flex;
  flex-direction: column;

  padding: 10px 30px;
}

.upper {
  display: flex;
  width: 100%;
}

.graph1 {
  grid-area: graph1;
  flex: 1;
}

.graph2 {
  grid-area: graph2;
  flex: 1;
}

.slider {
  grid-area: slider;
  display: grid;
  place-items: center;

  padding: 10px 30px;
}
.khaliGraph1 {
  grid-area: khaliGraph1;
  display: grid;
  place-items: center;
}
.khaliGraph2 {
  grid-area: khaliGraph2;
  display: grid;
  place-items: center;
}

@media (max-width: 1280px) {
  .root {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 940px 650px 650px;
    /* background-color: black; */
    grid-template-areas: "row1" "small" "khaliGraph1" "khaliGraph2";
    padding: 15px;
    gap: 10px;
  }

  .row1 {
    height: 1000px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "fees" "table";
    grid-gap: 30px;
    padding: 30px;
  }

  .small {
    grid-area: small;

    padding: 0px 30px;
  }
}

@media (max-width: 768px) {
  .root {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 850px 650px 650px;

    grid-template-areas: "row1" "small" "khaliGraph1" "khaliGraph2";
    padding: 15px;
    gap: 10px;
  }
  .khaliGraph1 {
    grid-area: khaliGraph1;
    /* background-color: aqua; */
    display: grid;
    place-items: center;
    padding: 40px;
  }
  .khaliGraph2 {
    grid-area: khaliGraph2;
    /* background-color: rgb(66, 133, 196); */
    display: grid;
    place-items: center;
    padding: 40px;
  }
}

@media (max-width: 700px) {
  .root {
    max-width: 100%;
    overflow-x: hidden;
  }
  .row1 {
    height: 800px;
  }

  .feesSaved {
    height: 100%;
    width: 100%;
  }

  .employerTable {
    height: 100%;
  }
}

@media (max-width: 576px) {
  .root {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 800px 400px 400px;
    /* background-color: black; */
    grid-template-areas: "row1" "small" "khaliGraph1" "khaliGraph2";
    padding: 0px;
    gap: 10px;
  }

  .picker {
    display: flex;
    padding-bottom: 20px;
  }
  .row1 {
    height: 870px;
    grid-template-columns: 1fr;
    grid-template-rows: 350px 490px;
    grid-template-areas: "fees" "table";
    grid-gap: 20px;
    padding: 10px;
    padding-bottom: 0px;
  }

  .small {
    padding: 0px 10px;
  }
  .khaliGraph1 {
    grid-area: khaliGraph1;
    /* background-color: aqua; */
    display: grid;
    place-items: center;
    padding: 10px;
  }
  .khaliGraph2 {
    grid-area: khaliGraph2;
    /* background-color: rgb(66, 133, 196); */
    display: grid;
    place-items: center;
    padding: 10px;
  }
}
