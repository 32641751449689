@font-face {
  font-family: sspb;
  src: url("../../../Assets/Fonts/SourceSansPro-Regular.otf");
  font-display: swap;
}

.mainCard {
  height: 100%;
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.cardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  /* height: 10vh; */
  padding: 10px 20px;
}

.headerLeft {
  /* background-color: pink; */
  width: 150px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  font-family: Lato;
  font-size: 20px;
}

.headerRight {
  /* background-color: pink; */
  width: 150px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-family: sspb;
  font-size: 24px;
}

.afterDecimal {
  font-family: sspb;
  font-size: 16px;
  padding-bottom: 3.5px;
}

.seeMore {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
