@font-face {
  font-family: sspb;
  src: url("../../../../Assets/Fonts/SourceSansPro-Regular.otf");
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("../../../../Assets/Fonts/Lato-Regular.ttf");
  font-display: swap;
}

.graphCard {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.04);
  width: 90%;
  height: 90%;
  align-items: center;
}

.container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 80px 64px 40px 400px;
  grid-template-areas: "pickers pickers" "search search" "data data" "accord accord";
}

.search {
  grid-area: search;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchDiv {
  width: 95%;
  padding: 10px 0px;
  /* background-color: pink; */
}

.empty {
  grid-area: empty;
}

.accordArea {
  grid-area: accord;
  padding: 10px 40px;
  /* overflow: auto; */
  width: 100%;
  display: flex;
  flex-direction: column;
}

.processpicker {
  flex: 1;
}

.graphArea {
  grid-area: graph;
  /* display: flex;
    justify-content: center;
    align-items: center; */
  margin-top: 20px;
}

.accordCont {
  overflow: auto;
  height: 100%;
  flex: 11;
}

.dataPickerWrapper {
  width: 95%;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

.pickers {
  grid-area: pickers;
  display: grid;
  grid-template-columns: 10fr 3fr;
  grid-template-rows: 8vh;
  grid-template-areas: "textArea date";
  width: 100%;
  padding-left: 40px;
}

.netPickers {
  grid-area: netPickers;
  display: grid;
  grid-template-columns: 10fr 3fr;
  grid-template-rows: 10vh;
  grid-template-areas: "textArea date";
  width: 85%;
  margin: 0 auto;
}

.expandedContainer {
  width: 100%;
}

.text {
  /* background-color: lime; */
  grid-area: textArea;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.afterDecimal {
  font-size: 17px;
  padding-top: 5.5px;
}

.actualText {
  height: 4vh;
  font-size: 18px;
  display: flex;
  align-items: flex-start;
  /* background-color: coral; */
}

.value {
  height: 5vh;
  font-size: 30px;
  font-family: sspb;
}

.dataPicker {
  grid-area: data;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.datePicker {
  grid-area: date;
  /* display: flex;
    justify-content: flex-end; */
  /* background-color: blueviolet; */
}

.pieChart {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.pieCard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92%;
  width: 90%;
}

@media (max-width: 1440px) {
  .accordArea {
    /* background-color: lightblue; */
    grid-area: accord;
    padding: 10px 20px;
    overflow: auto;
  }

  .pickers {
    /* background-color: lightgoldenrodyellow; */
    grid-area: pickers;
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 10vh;
    grid-template-areas: "textArea date";
    width: 100%;
    padding-left: 40px;
  }

  .actualText {
    height: 4vh;
    font-size: 15px;
    display: flex;
    align-items: flex-end;
  }

  .value {
    height: 6vh;
    font-size: 27px;
  }
}

@media (max-width: 1024px) {
  .container {
    grid-template-columns: 1fr;
    grid-template-rows: 100px 500px 70px 70px auto;
    grid-template-areas: "pickers" "graph" "search" "data" "accord";
  }
  .pickers {
    width: 100%;
  }

  .accordCont {
    overflow: hidden;
    height: auto;
  }

  .searchDiv {
    width: 90%;
  }

  .pickers {
    /* background-color: lightgoldenrodyellow; */
    grid-area: pickers;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 10vh;
    grid-template-areas: "textArea date";
    width: 100%;
    padding-left: 40px;
  }

  .accordArea {
    /* background-color: lightblue; */
    grid-area: accord;
    padding: 30px 40px;
    overflow: visible;
  }
  .actualText {
    height: 4vh;
    font-size: 15px;
    display: flex;
    align-items: flex-end;
  }

  .value {
    height: 6vh;
    font-size: 28px;
  }
  .pieCard {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
    width: 90%;
  }
}

@media (max-width: 576px) {
  .container {
    grid-template-columns: 1fr;
    /* grid-template-rows: 10vh 40vh 5vh 10vh auto; */
    grid-template-rows: 70px 320px 40px 60px auto;
    grid-template-areas: "pickers" "graph" "search" "data" "accord";
  }
  .accordArea {
    padding: 0;
  }
  .actualText {
    height: 4vh;
    font-size: 13px;
    display: flex;
    align-items: flex-end;
  }
  .value {
    height: 6vh;
    font-size: 20px;
  }
  .pickers {
    grid-area: pickers;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 10vh;
    grid-template-areas: "textArea date";
    width: 100%;
    padding-left: 20px;
  }
  .graphArea {
    margin-top: 0px;
  }
}
