/* Preferences.module.css */
.container {
  padding: 34px;
  width: 100%;
  height: 100%;
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.container::-webkit-scrollbar {
  width: 8px;
}

.container::-webkit-scrollbar-track {
  background: #F1F1F1;
  border-radius: 4px;
}

.container::-webkit-scrollbar-thumb {
  background: #D1D5DB;
  border-radius: 4px;
}

.container::-webkit-scrollbar-thumb:hover {
  background: #9CA3AF;
}

.title {
  font-size: 28px;
  font-weight: 500;
  color: #1A1A1A;
  text-align: center;
  margin-bottom: 32px;
}

/* Stepper Styles */
.stepperContainer {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  position: relative;
  width: 100%;
}

.stepperContainer::before {
  content: '';
  position: absolute;
  top: 12px;
  left: 24px;
  right: 24px;
  height: 1px;
  background-color: #E5E7EB;
  z-index: 0;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  flex: 1;
}

.step:first-child {
  align-items: flex-start;
}

.step:nth-child(2) {
  align-items: center;
}

.step:last-child {
  align-items: flex-end;
}

.stepDot {
  width: 24px;
  height: 24px;
  border: 2px solid #E5E7EB;
  border-radius: 50%;
  background: white;
  margin-bottom: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stepDot::after {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #E5E7EB;
}

.activeStep .stepDot {
  border-color: #4361EE;
}

.activeStep .stepDot::after {
  background: #4361EE;
}

.completedStep .stepDot {
  border-color: #4361EE;
}

.completedStep .stepDot::after {
  background: #4361EE;
}

.stepLabel {
  font-size: 14px;
  color: #666666;
  white-space: nowrap;
}

.activeStep .stepLabel,
.completedStep .stepLabel {
  color: #4361EE;
}

/* Form Content */
.formContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
}

.subtitle {
  font-size: 16px;
  color: #1A1A1A;
  margin-bottom: 24px;
  font-weight: 500;
  text-align: center;
}

/* Preferences List */
.preferencesList {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 32px;
}

.preferenceItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background: rgba(67, 97, 238, 0.04);
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.preferenceItem:hover {
  background: rgba(67, 97, 238, 0.08);
}

.preferenceContent {
  display: flex;
  align-items: center;
  gap: 12px;
}

.iconWrapper {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.preferenceLabel {
  font-size: 15px;
  color: #1A1A1A;
  line-height: 1.4;
}

/* Checkbox Styles */
.checkboxWrapper {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: white;
  border: 2px solid #D1D5DB;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.checkbox:checked ~ .checkmark {
  background: #4361EE;
  border-color: #4361EE;
}

.checkbox:checked ~ .checkmark::after {
  content: '';
  position: absolute;
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Terms Section */
.termsContainer {
  background: rgba(67, 97, 238, 0.04);
  border-radius: 12px;
  padding: 16px;
  margin-top: auto;
  margin-bottom: 32px;
}

.termsWrapper {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  cursor: pointer;
}

.termsText {
  font-size: 14px;
  color: #666666;
  line-height: 1.5;
}

.link {
  color: #4361EE;
  text-decoration: underline;
}

.link:hover {
  color: #3651DE;
}

.errorText {
  color: #FF4D4F;
  font-size: 12px;
  margin-top: 8px;
}

/* Button Group */
.buttonGroup {
  display: flex;
  gap: 16px;
  margin-top: 32px;
}

.backButton,
.nextButton {
  flex: 1;
  height: 48px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.backButton {
  background: white;
  border: 1px solid #D1D5DB;
  color: #666666;
}

.backButton:hover {
  background: #F9FAFB;
  border-color: #9CA3AF;
}

.backButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.nextButton {
  background: #4361EE;
  border: none;
  color: white;
}

.nextButton:hover:not(:disabled) {
  background: #3651DE;
}

.nextButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Loading Button */
.loadingButton {
  flex: 1;
  height: 48px;
  border-radius: 8px;
  background: #4361EE;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Mobile Styles */
@media (max-width: 640px) {
  .container {
    padding: 24px 20px;
  }

  .title {
    font-size: 24px;
    margin-bottom: 24px;
  }

  .stepLabel {
    font-size: 12px;
  }

  .preferenceLabel {
    font-size: 14px;
  }

  .buttonGroup {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    background: white;
    box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1);
    margin: 0;
  }

  .backButton,
  .nextButton,
  .loadingButton {
    height: 44px;
    font-size: 16px;
  }

  .termsContainer {
    margin-bottom: 80px; /* Add space for fixed buttons */
  }
}