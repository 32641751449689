@font-face {
  font-family: SF;
  src: url("../../../Assets/Fonts/SourceSansPro-Black.otf");
  font-display: swap;
}

.title {
  font-family: SF;
  color: #416ce1;
  font-size: 16px;
}
