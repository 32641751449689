.wrapper {
  height: 100%;
  width: 100%;
}

.inputDiv {
  height: 40px;
  background-color: white;
  display: flex;
  border-radius: 20px;
  padding-right: 20px;
  padding-bottom: 5px;
  border: 1px solid #dfe1e5;
}

.inputDiv:hover {
  box-shadow: 0 1px 4px 0 rgba(32, 33, 36, 0.137);
}

.icon {
  height: 20px;
}

.input {
  width: 100%;
  flex: 1;
  background-color: white;
  border: none;
  outline: none;
}

.inputWrap {
  display: flex;
  flex: 1;
}

.iconWrap {
  padding: 5px 10px 10px 15px;
}
