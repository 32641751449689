@font-face {
  font-family: LatoB;
  src: url("../../../Assets/Fonts/Lato-Semibold.ttf");
  font-display: swap;
}

@font-face {
  font-family: sspb;
  src: url("../../../Assets/Fonts/SourceSansPro-Regular.otf");
  font-display: swap;
}

.container {
  height: 100%;
  width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  position: relative;
}

.title {
  padding: 10px;
  display: flex;
}

.name {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;
  font-family: LatoB;
  font-size: 20px;
}

.company {
  padding-left: 30px;
  font-family: sspb;
  font-size: 13px;
}

.headerFees {
  padding-right: 5px;
}

.amount {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  font-family: sspb;
  font-size: 22px;
}

.graph {
  height: 100%;
}

.afterDecimal {
  font-size: 14px;
  padding-top: 8px;
}

.center {
  width: 248px;
  height: 84px;
  opacity: 1;
  border-radius: 14px;
  background-color: #efefef;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-family: LatoB;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #31333e;
  padding: 15px;

  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);

  z-index: 2;
}

.toolTip {
  color: #535353;
  border-radius: 5px;
  border: 1px solid rgb(97, 97, 97);
  background-color: white;
  padding: 5px;
  font-size: 15px;
  font-family: sspb;
}

.money {
  font-family: sspb;
  color: #2cb65a;
}

.xVal {
  font-family: sspb;
}

.chartCont {
  height: 100%;
  padding-top: 20px;
  padding-right: 20px;
}

.smallmoney {
  font-family: sspb;
  font-size: 14px;
}

.smalltoolTip {
  color: #535353;
  border-radius: 5px;
  border: 1px solid rgb(97, 97, 97);
  background-color: white;
  padding: 5px;
  font-size: 13px;
  font-family: sspb;
}

@media (max-width: 768px) {
  .center {
    width: 200px;
    height: 84px;
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .toolTip {
    color: #535353;
    border-radius: 5px;
    border: 1px solid rgb(97, 97, 97);
    background-color: white;
    padding: 5px;
    font-size: 13px;
    font-family: sspb;
  }
  .smallmoney {
    font-family: sspb;
    font-size: 13px;
  }
  .afterDecimal {
    font-size: 12px;
  }
  .headerFees {
    font-size: 15px;
  }
  .amount {
    font-size: 19px;
  }
}
