/* @import '~antd/dist/antd.css';

@font-face {
	font-family: Lato;
	src: url('./Assets/Fonts/Lato-Regular.ttf');
	font-display: swap;
}

.App {
	font-family: Lato;
} */


.dm-sans-regular {
	font-family: "DM Sans", sans-serif;
	font-optical-sizing: auto;
	font-weight: 400; /* Regular weight */
	font-style: normal;
  }
  
  .dm-sans-bold {
	font-family: "DM Sans", sans-serif;
	font-optical-sizing: auto;
	font-weight: 700; /* Bold weight */
	font-style: normal;
  }