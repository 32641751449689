@font-face {
  font-family: sspb;
  src: url("../../../Assets/Fonts/SourceSansPro-Regular.otf");
  font-display: swap;
}

@font-face {
  font-family: sspBold;
  src: url("../../../Assets/Fonts/SourceSansPro-Semibold.otf");
  font-display: swap;
}

@font-face {
  font-family: LatoB;
  src: url("../../../Assets/Fonts/Lato-Medium.ttf");
  font-display: swap;
}

.container {
  display: flex;
  padding: 0px 20px;
}

.first {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.last {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.left {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
}

.leftop {
  color: #416ce1;
  font-size: 15px;
  font-family: LatoB;
}

.leftbottom {
  font-size: 14px;
}

.right {
  display: flex;
  flex: 1;
}

.updated {
  margin-left: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  font-family: sspb;
  font-size: 13px;
  width: 100%;
}

.date {
  font-family: sspBold;
}

.edit {
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  color: #416ce1;
  font-size: 17px;
  font-family: LatoB;
}

.number {
  font-family: sspB;
}

.cross {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  color: #f25f5f;
  font-size: 25px;
  margin-left: auto;
}

.trash {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

@media (max-width: 576px) {
  .trash {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
  .cross {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 5px;
    color: #f25f5f;
    font-size: 25px;
  }
  .container {
    display: flex;
    padding: 0px 15px;
  }
  .leftop {
    color: #416ce1;
    font-size: 13px;
    font-family: LatoB;
  }

  .leftbottom {
    font-size: 12px;
  }
  .first {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .last {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .updated {
    display: flex;
    align-items: center;
    padding: 0px;
    font-family: sspb;
    font-size: 12px;
  }

  .date {
    font-family: sspBold;
  }
}
