@font-face {
  font-family: latob;
  src: url("../../../../Assets/Fonts/Lato-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: ssfpsb;
  src: url("../../../../Assets/Fonts/SourceSansPro-Semibold.otf");
  font-display: swap;
}

@font-face {
  font-family: ssfp;
  src: url("../../../../Assets/Fonts/SourceSansPro-Regular.otf");
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("../../../../Assets/Fonts/Lato-Regular.ttf");
  font-display: swap;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: latob;
  font-size: 18px;
}

.warningContainer:hover {
  cursor: pointer;
}

.exclaim {
  width: 30px;
  height: 30px;
  margin: 0px 0px 0px 10px;
  cursor: pointer;
}

.noneDiv {
  display: none;
}
.outerContainer {
  position: relative;
}
.aModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 18vw;
  height: 25vh; */
  width: 337px;
  height: 211px;
  border-radius: 5px;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: none;
  z-index: 2;
  padding: 15px 20px;
}

.warningContainer {
  padding: 0px 20px;
}

.warningText {
  text-align: center;
  padding-bottom: 10px;
}

.warning {
  font-family: ssfpsb;
  font-size: 20px;
  padding: 10px 0px 0px 0px;
}

.warningCont {
  font-family: ssfp;
  font-size: 14px;
}

.editText {
  font-size: 17px;
  color: #416ce1;
  cursor: pointer;
  font-family: latob;
}

.editInputs {
  text-align: center;
  padding: 10px 0px 20px 0px;
}

.msg {
  padding: 0;
  margin: 0;
  font-family: ssfp;
  font-size: 14px;
}

.closeDiv {
  cursor: pointer;
  padding: 0;
}

.aModal:focus {
  outline: 0;
}

.loggedInModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 18vw;
  height: 25vh; */
  width: 450px;
  border-radius: 5px;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: none;
  z-index: 450;
}

.text {
  font-size: 15px;
  color: #404a57;
  padding: 0 20px;
}

.buttonCont {
  width: 100%;
}

.cross {
  display: flex;
  justify-content: flex-end;
}

.pointDiv {
  display: flex;
  padding: 10px;
}

.circle {
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: #416ce1;
}

.confirmSelection {
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 5px 20px;
  font-family: ssfp;
  color: white;
  font-size: 14px;
}

.info {
  color: #1782b8;
  font-size: 13px;
  font-family: ssfp;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
}

.iImage {
  height: 20px;
  width: 20px;
}

.radio {
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: #ffffff;
  border: 1px solid #416ce1;
}

.crossImage {
  height: 35px;
  width: 35px;
}

.mCont {
  display: flex;
  flex-direction: column;
}

.dModal {
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  /* width: 18vw;
    height: 25vh; */
  border-radius: 5px;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: none;
  z-index: 2;
  padding: 20px 25px 0 25px;
  width: 307px;
  height: 181px;
}
.dModal:focus {
  outline: 0;
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  -webkit-backdrop-filter: blur(2.3px);
  backdrop-filter: blur(2.3px);
  background-color: #7070702a;
  z-index: 450;
}

.pointCont {
  padding: 10px 20px;
}

.closeCont {
  position: relative;
}

.circleDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header2 {
  font-family: ssfpsb;
  padding: 20px;
  font-size: 20px;
}

.header {
  font-family: latob;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.14px;
  text-align: left;
  color: #777777;
}
.content {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.12px;
  text-align: left;
  color: #777777;
  margin-top: 30px;
}

.emailInput {
  border: 0;
  padding: 0 0 0 10px;
}
.emailInput:focus {
  outline: 0 !important;
}
.emailInput::placeholder {
  opacity: 0.64;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: left;
  color: #818ca5;
}
.inputOuter {
  padding: 15px 0 5px 0;
  border-bottom: 1px solid #818ca5;
}
.btnsDiv {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 5px 0;
}
.delBtnsDiv {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 5px 0;
}
.cancelBtn {
  padding: 5px 20px;
  border-radius: 5px;
  background-color: #bcbec6;
  color: #ffffff;
  border: 0;
  text-align: center;
  font-size: 14px;
  flex: 1;
}
.cancelBtn:focus {
  outline: 0;
  background-color: rgb(113, 114, 117);
}

.confirmBtn {
  border-radius: 5px;
  background-color: #416ce1;
  color: #ffffff;
  border: 0;
  text-align: center;
  font-size: 14px;
  padding: 5px 20px;
  flex: 1;
}
.confirmBtn:focus {
  outline: 0;
  background-color: #2355e0;
}
.removeBtn {
  width: 104.4px;
  height: 28.5px;
  border-radius: 5px;
  background-color: #d34a4a;
  color: #ffffff;
  border: 0;
  text-align: center;
}
.removeBtn:focus {
  outline: 0;
  background-color: #991c1c;
}

.closeButton {
  position: absolute;
  right: 0;
  top: 0;
  height: 45px;
  width: 45px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

@media (max-width: 768px) {
  .aModal {
    top: 50%;
    left: 50%;
  }
  .dModal {
    top: 50%;
    left: 50%;
  }
  .addButton {
    width: 125px;
    height: 125px;
  }

  .deleteButton {
    width: 40px;
    height: 40px;
    font-size: 25px;
  }
  .contentUnder {
    width: 125px;
  }
  .closeButton {
    position: absolute;
    right: 5px;
    top: 5px;
    height: 35px;
    width: 35px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }
}

@media (max-width: 576px) {
  .addButton {
    width: 100px;
    height: 100px;
  }

  .deleteButton {
    width: 35px;
    height: 35px;
    font-size: 22px;
  }
  .contentUnder {
    width: 100px;
  }
  .header {
    padding-top: 20px;
  }
  .loggedInModal {
    width: 100%;
    height: 90vh;
    overflow: auto;
  }
  .warningText {
    text-align: center;
    padding-bottom: 5px;
  }

  .warning {
    font-family: ssfpsb;
    font-size: 16px;
    padding: 5px 0px 0px 0px;
  }
  .header2 {
    font-family: ssfpsb;
    padding: 10px 20px;
    font-size: 17px;
  }
  .warningCont {
    font-size: 12px;
  }
  .warningContainer {
    padding: 0px 10px;
    font-size: 12px;
  }
  .info {
    font-size: 11px;
  }
  .iImage {
    height: 15px;
    width: 15px;
  }
  .text {
    font-size: 13px;
  }
}
