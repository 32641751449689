.wrapper {
  height: 100%;
}

.netWrapper {
  padding: 20px 0px;
  height: 100%;
  width: 85%;
}

@media (max-width: 1024px) {
  .wrapper {
    overflow: hidden;
  }
  .netWrapper {
    width: 95%;
  }
}

@media (max-width: 576px) {
  .wrapper {
    padding: 0;
  }
  .netWrapper {
    padding: 0px 0px;
    width: 100%;
  }
}
