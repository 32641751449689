/* MainScreen.module.css */
/* Button Styles */
.feesButton {
  background-color: transparent;
  color: #4361EE;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
  border: none;
  outline: none;
  padding: 0;
}

.feesButton:hover {
  color: #3651DE;
}

.employerButton {
  width: 300px;
  height: 48px;
  border-radius: 8px;
  background-color: #4361EE;
  border: none;
  outline: none;
  color: white;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.employerButton:hover {
  background-color: #3651DE;
}

.signupButton {
  height: 48px;
  padding: 0 24px;
  border-radius: 8px;
  background-color: #4361EE;
  color: white;
  font-size: 18px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signupButton:hover {
  background-color: #3651DE;
}

/* Modal Styles */
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 610px;
  max-height: 90vh;
  background-color: #FFFFFF;
  border-radius: 24px;
  outline: none;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.startModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 480px;
  height: 480px;
  background-color: #FFFFFF;
  border-radius: 24px;
  outline: none;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Scrollbar Styles */
.modal::-webkit-scrollbar {
  width: 8px;
}

.modal::-webkit-scrollbar-track {
  background: #F1F1F1;
  border-radius: 4px;
}

.modal::-webkit-scrollbar-thumb {
  background: #D1D5DB;
  border-radius: 4px;
}

.modal::-webkit-scrollbar-thumb:hover {
  background: #9CA3AF;
}

.modal {
  scrollbar-width: thin;
  scrollbar-color: #D1D5DB #F1F1F1;
}

/* Animations */
@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -48%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

.modal,
.startModal {
  animation: modalFadeIn 0.3s ease-out;
}

/* Modal Content Container */
.modalContent {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

/* Mobile Styles */
@media (max-width: 640px) {
  .modal,
  .startModal {
    width: 100%;
    height: 100%;
    border-radius: 0;
    position: fixed;
    top: 0;
    left: 0;
    transform: none;
    max-height: 100vh;
  }

  .employerButton,
  .signupButton {
    height: 44px;
    font-size: 16px;
  }
}

@media (min-width: 641px) and (max-width: 1024px) {
  .modal {
    width: 90%;
    max-width: 610px;
  }

  .startModal {
    width: 90%;
    max-width: 480px;
    height: auto;
    min-height: 480px;
  }
}

/* Z-index management */
.overlay {
  z-index: 1000;
}

.modal,
.startModal {
  z-index: 1001;
}

/* Make sure alerts appear above modals */
:global(.react-modal-overlay) {
  z-index: 1000;
}

:global(.react-modal-content) {
  z-index: 1001;
}