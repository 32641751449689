.center-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  text-align: center;
  font-size: 20px;
  color: #706c6c;
}

.buttons {
  margin: 1vw;
}

.head {
  padding: 0px;
  margin: 0px;
  font-weight: bold;
  font-size: 30px;
  letter-spacing: 2px;
}

.sub-head {
  color: #706c6c;
  margin-bottom: 30px;
}

.logo {
  width: 60px;
  height: 80px;
}
.logospan {
  margin-top: 25px;
  margin-bottom: 50px;
}

.sad {
  height: 240px;
  width: 330px;
}
