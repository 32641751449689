@font-face {
  font-family: LatoB;
  src: url("../../Assets/Fonts/Lato-Bold.ttf");
}

.left {
  text-align: left;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.23px;
  color: #404a57;
  font-family: LatoB;
  align-self: start;
  padding-left: 10px;
}

.container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 110px auto;
  grid-template-areas: "tabs" "timePeriod";
  padding: 0px 10px;
}

.tabs {
  grid-area: tabs;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
}

.timePeriod {
  grid-area: timePeriod;
}

.pageTitle {
  font-weight: bold;
  font-size: 18px;
  width: 95%;
}

.innerTabs {
  width: 100%;
  /* background-color: blueviolet; */
}

@media (max-width: 1024px) {
  .container {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 120px auto;
    grid-template-areas: "tabs" "timePeriod";
  }
}

@media (max-width: 576px) {
  .container {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 130px auto;
    grid-template-areas: "tabs" "timePeriod";
    padding: 0;
  }
  .tabs {
    grid-area: tabs;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0px;
  }
  .pageTitle {
    font-weight: bold;
    font-size: 15px;
    width: 95%;
    text-align: center;
  }
  .left {
    width: 100%;
    text-align: center;
    padding: 0 0 10px 0;
  }
}
