.container {
  padding: 34px;
  width: 100%;
  height: 100%;
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.container::-webkit-scrollbar {
  width: 8px;
}

.container::-webkit-scrollbar-track {
  background: #F1F1F1;
  border-radius: 4px;
}

.container::-webkit-scrollbar-thumb {
  background: #D1D5DB;
  border-radius: 4px;
}

.container::-webkit-scrollbar-thumb:hover {
  background: #9CA3AF;
}

.title {
  font-size: 28px;
  font-weight: 500;
  color: #1A1A1A;
  margin: 0 0 32px 0;
}

/* Stepper Styles */
.stepperContainer {
  display: flex;
  align-items: flex-start;
  margin-bottom: 32px;
  position: relative;
  width: 100%;
}

/* Add the continuous line behind */
.stepperContainer::before {
  content: '';
  position: absolute;
  top: 12px;
  left: 12px;
  right: 12px;
  height: 1px;
  background-color: #E5E7EB;
  z-index: 0;
}

.step {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  z-index: 1;
}

.step:first-child {
  align-items: flex-start;
}

.step:nth-child(2) {
  align-items: center;
}

.step:last-child {
  align-items: flex-end;
}

.stepDot {
  width: 24px;
  height: 24px;
  border: 2px solid #E5E7EB;
  border-radius: 50%;
  background: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.stepDot::after {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #E5E7EB;
}

.activeStep .stepDot {
  border-color: #4361EE;
}

.activeStep .stepDot::after {
  background: #4361EE;
}

.activeStep .stepLabel {
  color: #4361EE;
}

.stepLabel {
  font-size: 14px;
  color: #666666;
  white-space: nowrap;
}

.subtitle {
  font-size: 16px;
  color: #666666;
  margin-bottom: 32px;
  line-height: 1.5;
}

/* Form Section */
.formSection {
  flex: 1;
}

/* Form Grid */
.formGrid {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.formRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.label {
  font-size: 14px;
  color: #666666;
  display: flex;
  align-items: center;
  gap: 4px;
}

.required {
  color: #FF4D4F;
}

.input {
  height: 48px;
  padding: 0 16px;
  border: 1px solid #D1D5DB;
  border-radius: 8px;
  font-size: 16px;
  color: #1A1A1A;
  width: 100%;
  transition: all 0.2s;
  background: white;
}

.input::placeholder {
  color: #666666;
}

.input:focus {
  outline: none;
  border-color: #4361EE;
  box-shadow: 0 0 0 2px rgba(67, 97, 238, 0.1);
}

.inputError {
  border-color: #FF4D4F !important;
}

.inputError:focus {
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.1) !important;
}

.errorText {
  font-size: 12px;
  color: #FF4D4F;
  margin-top: 4px;
}

/* Gender Selection */
.genderGroup {
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 24px;
  margin-top: 8px;
}

.radioLabel {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
}

.radioInput {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.radioButton {
  width: 20px;
  height: 20px;
  border: 2px solid #D1D5DB;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  transition: all 0.2s;
}

.radioInput:checked + .radioButton {
  border-color: #4361EE;
}

.radioInput:checked + .radioButton::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background: #4361EE;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Date Input - Improved */
.dateInput {
  width: 100%;
}

.dateInputField {
  height: 48px !important;
  padding: 0 16px !important;
  border: 1px solid #D1D5DB !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  color: #1A1A1A !important;
  background: white !important;
}

.dateInputField input {
  padding: 0 !important;
  height: 100% !important;
  font-size: 16px !important;
}

.dateInputField::before,
.dateInputField::after {
  display: none !important;
}

.dateInputField fieldset {
  border: none !important;
}

/* Customize the date picker popup */
:global(.MuiPickersModal-dialogRoot) {
  border-radius: 12px !important;
}

:global(.MuiPickersBasePicker-pickerView) {
  max-width: 348px !important;
}

:global(.MuiPickersDay-day) {
  width: 36px !important;
  height: 36px !important;
  font-size: 14px !important;
}

:global(.MuiPickersDay-daySelected) {
  background-color: #4361EE !important;
}

:global(.MuiPickersDay-current) {
  color: #4361EE !important;
}

:global(.MuiPickersYear-yearSelected) {
  color: #4361EE !important;
}

:global(.MuiPickersYear-root) {
  height: 64px !important;
}

/* Password Field */
.passwordGroup {
  position: relative;
}

.passwordInput {
  padding-right: 64px;
}

.hideButton {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0;
  font-size: 14px;
  color: #666666;
  cursor: pointer;
}

/* Password Validation Styles */
.passwordValidation {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.validationItem {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666666;
  font-size: 14px;
}

.validationItem.valid {
  color: #52C41A;
}

.validationItem.invalid {
  color: #666666;
}

.validationDot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: currentColor;
}

/* Button Group */
.buttonGroup {
  display: flex;
  gap: 16px;
  margin-top: 32px;
}

.backButton,
.nextButton {
  flex: 1;
  height: 48px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.backButton {
  background: white;
  border: 1px solid #D1D5DB;
  color: #666666;
}

.backButton:hover {
  background: #F9FAFB;
  border-color: #9CA3AF;
}

.nextButton {
  background: #4361EE;
  border: none;
  color: white;
}

.nextButton:hover:not(:disabled) {
  background: #3651DE;
}

.nextButton:disabled {
  background: #A5B4FC;
  cursor: not-allowed;
}

/* Responsive Styles */
@media (max-width: 640px) {
  .container {
    padding: 24px 20px;
  }

  .title {
    font-size: 24px;
    margin-bottom: 24px;
  }

  .formRow {
    grid-template-columns: 1fr;
  }

  .genderGroup {
    grid-template-columns: 1fr 1fr;
  }

  .buttonGroup {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    background: white;
    box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1);
    margin: 0;
  }

  .input,
  .dateInputField,
  .backButton,
  .nextButton {
    height: 44px;
  }
}