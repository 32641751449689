.sidebar_inner {
  width: 30%;
  position: fixed;
  box-shadow: 1px 0 15px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 400;
  animation: sliding 0.4s ease-out;
  overflow-y: scroll;
}
@keyframes sliding {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
.closeBtn {
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  cursor: pointer;
}
.closeBtn:focus {
  outline: 0;
}
.logo {
  padding: 7% 5%;
  display: flex;
  justify-content: flex-start;
}
.headingText {
  color: #0c4090;
  font-weight: 600;
}
.sidebar_menu {
  list-style: none;
  text-decoration: none;
  margin: 0;
  padding: 10px;
}

.sidebar_inner::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 5px;
}

.cross {
  width: 40px;
  height: 40px;
}

.sidebar_inner::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 5px;
}

.sidebar_inner::-webkit-scrollbar-thumb {
  background-color: #416ce1;
  border-radius: 10px;
}

.bold {
  font-size: 16px;
  font-family: LatoBold;
  color: #416ce1;
}

.sidebar_menu li {
  display: flex;
  justify-content: flex-start;
  padding: 4% 0%;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: none;
  line-height: 1;
  letter-spacing: normal;
  color: #31333e;
}

.link {
  text-decoration: none;
  cursor: pointer;
  color: #31333e;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  outline: none !important;
}
.link:hover {
  font-weight: bold;
  color: #31333e;
  text-decoration: none;
}
.img {
  height: 40px;
  width: 40px;
  margin-right: 2px;
}

.img1 {
  width: 100%;
  height: 100%;
}
.icon {
  width: 30.1px;
  height: 30.5px;
  margin-right: 6px;
}

@media only screen and (max-width: 768px) {
  .sidebar_inner {
    width: 40%;
  }
}

@media only screen and (max-width: 668px) {
  .sidebar_inner {
    width: 35%;
  }
}

@media (max-height: 600px) {
  .logo {
    padding: 5% 5%;
  }
  .sidebar_menu li {
    padding: 3% 0%;
  }
}

@media only screen and (max-width: 568px) {
  .sidebar_inner {
    width: 60%;
  }
}
