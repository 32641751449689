@font-face {
  font-family: LatoB;
  src: url("../../Assets/Fonts/Lato-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("../../Assets/Fonts/Lato-Regular.ttf");
  font-display: swap;
}

.root {
  text-align: center;
  padding: 50px 0px;
}

.root2 {
  text-align: center;
  padding: 150px 0px;
}

.element + .element {
  margin-top: 30px;
}

.button {
  width: 153px;

  border-radius: 5px;
  background-color: #416ce1;
  padding: 10px 20px;
  color: white;

  transition: transform 0.3s;

  text-decoration: none !important;
}

.button:hover {
  color: white;
}

.header {
  font-family: LatoB;
  font-size: 25px;
  /* font-weight: 600; */
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #5a5a5a;
}

@media (max-width: 400px) {
  .img {
    height: 250px;
  }
}
