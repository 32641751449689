@font-face {
  font-family: LatoSB;
  src: url("../../../Assets/Fonts/Lato-Semibold.ttf");
  font-display: swap;
}

@font-face {
  font-family: sspb;
  src: url("../../../Assets/Fonts/SourceSansPro-Regular.otf");
  font-display: swap;
}

.container {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: white;
  /* border: 1px solid #c6cbde; */
  box-sizing: border-box;
  font-family: LatoSB;
  color: #404a57;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: -0.21px;
}

.title {
  flex: 3;
  display: flex;
  align-items: center;
}

.money {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: sspb;
  font-size: 20px;
}

.category {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: pink; */
}

.date {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: LatoSB;
}

.circleCont {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  flex: 11;
  padding: 20px 0px;
}

.circle {
  height: 10px;
  width: 10px;
  border-radius: 5px;
}
