@font-face {
  font-family: sspb;
  src: url("../../../Assets/Fonts/SourceSansPro-Semibold.otf");
  font-display: swap;
}

@font-face {
  font-family: ssp;
  src: url("../../../Assets/Fonts/SourceSansPro-Regular.otf");
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("../../../Assets//Fonts/Lato-Regular.ttf");
  font-display: swap;
}

.dropDownContainer {
  height: auto;
  background-color: #f8f8f8;
  border-bottom: 1px solid #c6cbde;
  padding: 10px 0px;
}

.centerCol {
  display: flex;
  justify-content: center;
  align-items: center;
}

.topLeft {
  height: auto;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #416ce1;
  font-family: ssp;
  white-space: -moz-pre-wrap; /* Mozilla */
  white-space: -hp-pre-wrap; /* HP printers */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: pre-wrap; /* CSS 2.1 */
  white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word; /* IE */
  word-break: break-all;
}

.bottomLeft {
  height: auto;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #404a57;
}

.leftBottomMoney {
  font-family: sspb;
}

.topRight {
  height: 4vh;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 17px;
  font-family: sspb;
}

.bottomRight {
  height: 4vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  color: #404a57;
  font-family: sspb;
}

.circle {
  height: 10px;
  width: 10px;
  border-radius: 5px;
}

@media (max-width: 1024px) {
  .topLeft {
    align-items: flex-end;
  }
}

@media (max-width: 576px) {
  .topLeft {
    padding: 5px 0px;
  }

  .bottomLeft {
    padding: 0 0 5px 0;
  }

  .topRight {
    min-height: 3vh;
    height: auto;
    justify-content: center;
    padding-right: 40px;
    font-size: 19px;
  }

  .bottomRight {
    min-height: 3vh;
    height: auto;
    justify-content: center;
    padding-right: 40px;
    font-size: 14px;
  }
}
