@font-face {
  font-family: SF;
  src: url("../../Assets/Fonts/SourceSansPro-Black.otf");
  font-display: swap;
}

.tp {
  font-family: SF;
  padding: 0px 20px 0px 10px;
}
