.container {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 256px 7fr 2fr;
  grid-template-rows: 90px auto auto;
  grid-template-areas: "sidebar search username" "sidebar maincontent maincontent" "sidebar footer footer";
  height: auto;
  min-height: 100vh;
}

.sidebar {
  grid-area: sidebar;
}

.avatar {
  padding: 0 10px;
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.searchDiv {
  grid-area: search;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userName {
  grid-area: username;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #404a57;
  font-size: 20px;
  font-weight: 500;
  padding: 5px 0px;
}

.main_content {
  grid-area: maincontent;
  background-color: #f5f5f5;
  height: auto;
  overflow: auto;
  min-height: 90vh;
}
.buffer {
  min-height: calc(90vh);
}
.searchBarContainer {
  width: 100%;
  padding: 40px;
}

.footer{
  grid-area: footer;
  
}

@media (max-width: 1024px) {
  .container {
    margin: 0;
    padding: 0;
    grid-template-columns: 230px 5fr 3fr;
    grid-template-rows: 100px auto auto;
    height: auto;
  }
  .main_content {
    grid-area: maincontent;
    height: auto;
    min-height: 90vh;
  }
}

@media (max-width: 768px) {
  .container {
    grid-template-columns: 7fr 5fr;
    grid-template-rows: 60px 70px auto auto;
    grid-template-areas: "sidebar username" "search search" "maincontent maincontent" "footer footer";
  }
  .main_content {
    grid-area: maincontent;
    height: auto;
    min-height: 82vh;
  }
  .userName {
    background-color: white;
  }
}



@media (max-width: 576px) {
  .container {
    grid-template-columns: 4fr 7fr;
  }
  
  .searchBarContainer {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
  }
  .main_content {
    height: auto;
    min-height: 84vh;
  }
  .userName {
    grid-area: username;

    color: #404a57;
    font-size: 16px;
    font-weight: 500;
  }
  .userNameText {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
