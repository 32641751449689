@media (max-width: 576px) {
  .container {
    color: #22242c95;
    width: 330px;
    padding: 20px 20px;
    margin-top: 5vh;
  }
}

@media (min-width: 900px) {
  .container {
    color: #22242c95;
    width: 400px;
    padding: 20px 20px;
    margin-top: 5vh;
  }
}
