@font-face {
  font-family: LatoBO;
  src: url('../../../Assets/Fonts/Lato-Bold.ttf');
}

@font-face {
  font-family: sspb;
  src: url('../../../Assets/Fonts/SourceSansPro-Regular.otf');
}

.container {
  display: flex;
  background-color: #f2f2f2;
  padding: 10px 0px;
  font-family: LatoBO;
  font-size: 14px;
  color: #31333e;
}

.name {
  flex: 8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.strat {
  flex: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sspb;
  font-size: 18px;
}

.amount {
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sspb;
  font-size: 18px;
}

.afterDecimal {
  font-family: sspb;
  font-size: 15px;
  padding-top: 2px;
}
