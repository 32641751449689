@font-face {
  font-family: Lato;
  src: url("../../Assets/Fonts/Lato-Regular.ttf");
  font-display: swap;
}

/* Login Container */
.loginContainer {
  position: relative;
  display: inline-block;
}

/* Login Button */
.loginButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #ffffff;
  border: 1px solid #4361EE;
  border-radius: 6px;
  color: #4361EE;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.loginButton:hover {
  background: #4361EE;
  color: #ffffff;
}

/* Dropdown Icon */
.dropdownIcon {
  transition: transform 0.2s ease;
  font-size: 18px;
}

.dropdownIconOpen {
  transform: rotate(180deg);
}

/* Login Dropdown */
.loginDropdown {
  position: absolute;
  top: 100%;
  right: 0;
  width: 180px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  margin-top: 8px;
  opacity: 0;
  transform: translateY(-10px);
  animation: dropdownFade 0.2s ease forwards;
  z-index: 100;
}

@keyframes dropdownFade {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.loginDropdownItem {
  width: 100%;
  padding: 12px 16px;
  background: none;
  border: none;
  text-align: left;
  color: #1E293B;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.loginDropdownItem:hover {
  background-color: #F8FAFC;
  color: #4361EE;
}

/* Existing Modal Styles */
.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 23vw;
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  outline: none;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(112, 112, 112, 0.16);
  backdrop-filter: blur(2.3px);
  z-index: 1000;
}

.closeButton {
  position: absolute;
  right: 15px;
  top: 15px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.crossButton {
  width: 24px;
  height: 24px;
}

.heading {
  font-family: Lato;
  text-align: center;
  padding: 30px 0 0;
  color: #1E293B;
}

.belowHeader {
  color: #64748B;
  font-size: 16px;
  text-align: center;
  padding: 8px 0 24px;
}

.fromFeesBtn {
  color: #4361EE;
  background: none;
  border: none;
  padding: 0;
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 1630px) {
  .Modal {
    width: 30vw;
  }
}

@media (max-width: 1280px) {
  .Modal {
    width: 40vw;
  }
}

@media (max-width: 1024px) {
  .Modal {
    width: 50vw;
  }
}

@media (max-width: 768px) {
  .Modal {
    width: 70vw;
  }
  .crossButton {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 576px) {
  .Modal {
    width: 90vw;
    height: auto;
    max-height: 90vh;
  }
  
  .loginButton {
    width: 100%;
    justify-content: center;
  }
  
  .loginDropdown {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}