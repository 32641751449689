@font-face {
  font-family: LatoM;
  src: url("../../../Assets/Fonts/Lato-Medium.ttf");
}

.container {
  display: flex;
  justify-content: space-around;
  background-color: #e8e8e8;
  border-radius: 5px;
  width: 100%;
  font-family: LatoM;
  padding-right: 15px;
  font-size: 14px;
  padding-top: 0;
  padding-bottom: 0;
  height: 90%;
  color: #404a57;
}

.datePicker {
  position: relative;
  cursor: pointer;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.amountPicker {
  position: relative;
  cursor: pointer;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1024px) {
  .datePicker {
    position: relative;
    cursor: pointer;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .amountPicker {
    position: relative;
    cursor: pointer;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    display: none;
  }

  .category {
    display: none;
  }
}
