.image1 {
  flex: 1;
  display: flex;
  justify-content: center;
}
.mainCard {
  height: 100%;
  width: 95%;
  padding: 20px;
  display: flex;
  margin-left: 20px;
  flex-direction: column;
  position: relative;
}
.title {
  padding: 10px;
  display: flex;
  margin-bottom: 20px;
}
.name {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;
  font-family: LatoB;
  font-size: 20px;
}

.headerFees {
  padding-right: 5px;
}

.innerImage1 {
  position: relative;
  flex: 1;
  height: 90%;
  width: 100%;
  min-width: 250px;
  min-height: 400px;
  max-width: 400px;
  max-height: 700px;
}
.empImg1 {
  position: relative;
  flex: 1;
  height: 10%;
  width: 10%;
  min-width: 55px;
  min-height: 55px;
  max-width: 55px;
  max-height: 55px;
  margin-left: 25px;
  margin-top: 20px;
}
.row123 {
  display: flex;
  margin-top: 70px;
  align-self: center;
  min-height: 100px;
}

.title1 {
  margin-top: 26px;
  flex-direction: column;
  margin-left: 20px;
}

.einText1 {
  font-size: 14px;
  color: gray;
}

.mainText1 {
  font-weight: 700;
  font-size: 17px;
}

.row11 {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 50px;
  margin-top: 100px;
}

.column11 {
  width: 50%;
  margin-top: -30px;
}
.column21 {
  width: 50%;
  height: 240px;
  margin-top: 50px;
  margin-left: 20px;
  margin-right: 30px;
}

.stepper {
  font-size: 21px;
  font-weight: bold;
}
.feesGraph1 {
  width: 40%;
  height: 350px;
  margin-left: 50px;
}

.column31 {
  width: 100%;

  flex-direction: row;
  display: flex;
}
.column41 {
  width: 60%;
  height: 350px;
  border-color: #31333e;
  border-width: 2;
}

.row10 {
  /* grid-area: row2; */
  display: flex;
  max-height: 1250px;
  flex-direction: column;
}

.root {
  margin-top: 90px;
  display: grid;
  grid-template-rows: auto auto 25px auto;
  grid-template-columns: 1fr;
  grid-template-areas: 'row1' 'row12' 'row2' 'empty' 'adds';
}

.invisibleRoot {
  margin-top: 90px;
  display: grid;
  grid-template-rows: auto 0vh 35px auto;
  grid-template-columns: 1fr;
  grid-template-areas: 'row1' 'row12' 'row2' 'empty' 'adds';
}

.empty {
  grid-area: empty;
  display: grid;
  place-items: center;
}

.row1 {
  min-height: 250px;
  grid-area: row1;
  display: flex;
  flex-direction: row;
}

.searchHeader {
  flex: 1;
}

.searchParent {
  width: 90%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-self: center;

  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #31333e;
  margin-top: 200px;
}

.empty {
  flex: 0.2;
}
.empty1 {
  flex: 0.2;
  margin-top: -150px;
  align-self: center;
}
.image {
  flex: 1;
  display: flex;
  justify-content: center;
}

.innerImage {
  position: relative;
  flex: 1;
  height: 90%;
  width: 100%;
  min-width: 250px;
  min-height: 400px;
  max-width: 400px;
  max-height: 700px;
}

/* ********************************************* */

.row2 {
  /* grid-area: row2; */
  display: flex;
  max-height: 1050px;
  flex-direction: column;
}

.empty {
  flex: 1;
}

.empty1 {
  flex: 1;
}

.blur {
  position: absolute;
  height: 100%;
  width: 90%;
  top: 0;
  left: 5%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.myButton {
  width: 300px;
  height: 40px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #416ce1;
  border: none;
  outline: none !important;
  color: white;
  font-size: 15px;
}

.btnOuter {
  width: 100%;
  height: 34%;
  display: -ms-grid;
  display: grid;
  place-items: center;
  -webkit-backdrop-filter: blur(5.3px);
  backdrop-filter: blur(5.3px);
}

@supports (backdrop-filter: none) {
  .btnOuter {
    backdrop-filter: blur(10px);
  }
}

@supports not (backdrop-filter: none) {
  .btnOuter {
    background: rgba(255, 255, 255, 0.9);
  }
}

/* ********************************************* */

@media (max-width: 1025px) {
  .innerImage {
    height: 80%;
    padding: 2.5%;
  }
  .invisibleRoot {
    margin-top: 90px;
    grid-template-rows: auto auto 0vh 25px auto;
  }

  .root {
    margin-top: 90px;
    grid-template-rows: auto auto auto 25px auto;
  }

  .row1 {
    min-height: 400px;
    flex-direction: column-reverse;
  }
}

/* @media (max-width: 769px) {
  .invisibleRoot {
    margin-top: 90px;
    grid-template-rows: auto 0vh 35px auto;
  }

  .root {
    margin-top: 90px;
    grid-template-rows: auto auto 35px auto;
  }
  .row2 {
    flex-direction: column;
    max-height: 900px;
    overflow: hidden;
  }
  .pickPie {
    flex: 1;
  }
  .pickerOuter {
    align-items: center;
    margin-bottom: 35px;
  }
  .outerPicker {
    align-items: center;
    width: 100%;
  }
  .pickerActual {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tableOuter {
    max-height: 400px;
  }
  .tableInner {
    height: 400px;
  }
  .pieOuter {
    width: 350px;
  }
  .searchParent {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-self: center;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #31333e;
  }
  .adds {
    padding: 55px 0;
    grid-area: adds;
    min-height: 290px;
  }
  .innerImage {
    max-width: 400px;
    max-height: 500px;
  }
} */
@media (min-width: 1025px) {
  .pickPie {
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: -160px;
    margin-left: 10%;
  }

  .pickerOuter {
    flex: 1;
    width: 100%;

    display: flex;
    margin-bottom: 35px;
  }
  .outerPicker {
    display: flex;
    flex: 3;
    padding: 0px 80px;
  }
  .pickerHead {
    flex: 2;
    font-size: 20px;
    font-weight: 500;
    width: 100%;
    font-stretch: normal;
    font-style: normal;

    letter-spacing: normal;
    color: #31333e;
    margin-bottom: 10px;
    margin-right: -50px;
  }
  .pickerActual {
    width: 100%;
    flex: 5;
  }

  .pieOuter {
    flex: 4;
    width: 100%;

    display: grid;
    /* background-color: darkcyan; */
  }

  .tableOuter {
    display: flex;
    margin-left: 10px;
    min-height: 350px;
    max-height: 500px;
    height: 600px;
    width: 100%;
    margin-top: 70px;
    margin-bottom: 100px;
  }

  .tableInner {
    width: 100%;
    height: 80%;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
  }
  .row12 {
    min-height: 150px;
    grid-area: row12;
    /* display: flex; */
    flex-direction: column;
    margin-top: 550px;
    align-self: center;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .spe {
    margin-left: 50px;
  }
  .search {
    flex: 1;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -130px;
  }
  .empImg {
    position: relative;
    flex: 1;
    height: 40%;
    width: 40%;
    min-width: 170px;
    min-height: 170px;
    max-width: 170px;
    max-height: 170px;
    margin-left: 40px;
    margin-top: 20px;
  }

  .adds {
    padding: 55px 0;
    grid-area: adds;
    height: 370px;
  }
}

@media (max-width: 576px) {
  .searchParent {
    margin-bottom: 40px;
    margin-top: 5px;
  }
  .pickerHead {
    font-size: 14px;
    padding: 0;
    margin-bottom: 2px;
  }
  .outerPicker {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 0px 20px 20px 20px;
  }
  .empty1 {
    flex: 0;
  }
  .row12 {
    min-height: 150px;
    grid-area: row12;
    /* display: flex; */
    flex-direction: column;
    margin-top: 130px;
    align-self: center;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .spe {
    margin-left: 20px;
    font-size: 17px;
  }
  .empImg {
    position: relative;
    flex: 1;
    height: 20%;
    width: 20%;
    min-width: 90px;
    min-height: 90px;
    max-width: 90px;
    max-height: 90px;
    margin-left: 20px;
    margin-top: 20px;
  }
  .adds {
    padding: 55px 0;
    grid-area: adds;
    height: 370px;
  }
  .search {
    flex: 1;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -70px;
  }
  .pickPie {
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .empty {
    flex: 1;
  }
  .row11 {
    margin-top: -185px;
  }

  .pickerOuter {
    flex: 1;
    width: 100%;
    align-self: center;
    display: flex;
    margin-bottom: 35px;
  }
  .outerPicker {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-self: center;
    padding: 0px 40px;
    margin-top: 100px;
  }
  .pickerHead {
    flex: 1;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #31333e;
  }
  .pickerActual {
    flex: 1;
  }

  .pieOuter {
    flex: 4;
    width: 85%;
    align-self: center;
    display: grid;
    /* background-color: darkcyan; */
    place-items: center;
  }

  .tableOuter {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 350px;
    max-height: 500px;
    height: 600px;
    margin-top: 60px;
  }

  .empty1 {
    flex: 1;
  }

  .tableInner {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
  }
  .row11 {
    flex-direction: column;
  }
  .column31 {
    flex-direction: column;
  }
  .column21 {
    width: 95%;
    margin-top: 50px;
  }
  .stepper {
    margin-left: 30px;
  }
  .pickPie {
    margin-top: 130px;
  }
  .feesGraph1 {
    width: 90%;
    align-self: center;
    margin-top: 60px;
    margin-left: 0;
  }
  .column41 {
    margin-top: 10%;
    width: 90%;
  }
  .row10 {
    margin-top: 0%;
    margin-bottom: 165px;
  }
}
