@font-face {
  font-family: LatoB;
  src: url("../../../../../Assets/Fonts/Lato-Semibold.ttf");
  font-display: swap;
}

@font-face {
  font-family: sspp;
  src: url("../../../../../Assets/Fonts/SourceSansPro-Regular.otf");
  font-display: swap;
}

.outerClass {
  width: 90%;
  padding: 20px 30px;
  position: relative;
  height: 90%;
}
.header {
  display: flex;
  justify-content: space-between;
}
.headTag {
  font-size: 18px;
}
.amtTag {
  font-family: sspp;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #fa4b4b;
}
.center {
  width: 248px;
  height: 84px;
  opacity: 0.8;
  border-radius: 14px;
  background-color: #efefef;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-family: LatoB;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: center;
  color: #31333e;
  padding: 15px;
}

@media (max-width: 768px) {
  .outerClass {
    width: 100%;
    
    position: relative;
  }
  .center {
    width: 200px;
    height: 84px;
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .outerClass {
    width: 100%;
    padding: 10px 10px;
    position: relative;
  }
  .center {
    width: 200px;
    height: 84px;
    font-size: 14px;
  }
}
