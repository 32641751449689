.main {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title > img {
  width: 90%;
  height: 280px;
}
.title > span {
  font-size: 21px;
  font-family: 'Franklin Gothic Medium', Arial, sans-serif;
  width: 70%;
  align-self: center;
  text-align-last: center;
  margin-top: 20px;
  font-weight: 500;
  color: black;
}

.secondText {
  margin-left: -45px;
  flex-direction: column;
  display: flex;
  margin-top: 30px;
}

.firstSpan {
  font-size: 19px;
  color: #246ddb;
}

.secondSpan {
  font-size: 19px;
  color: #666a77;
  margin-top: 5px;
}

.loginButton {
  width: 180px;
  height: 45px;
  border-radius: 2px;
  background-color: rgb(15, 111, 207);
  color: white;
  border: none;

  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  align-self: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

@media (max-width: 586px) {
  .title > img {
    width: 80%;
    height: 180px;
  }
  .title > span {
    font-size: 17px;
    font-family: 'Franklin Gothic Medium', Arial, sans-serif;
    width: 80%;
    align-self: center;
    text-align-last: center;
    margin-top: 20px;
    font-weight: 500;
    color: black;
  }
  .secondText {
    margin-left: 15px;
    flex-direction: column;
    display: flex;
    margin-top: 30px;
  }
  .firstSpan {
    font-size: 15px;
    color: #246ddb;
  }

  .secondSpan {
    font-size: 15px;
    color: #666a77;
    margin-top: 5px;
  }
}
