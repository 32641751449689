.toggle_button {
  height: 24px;
  width: 32px;
  margin: 10px;
  background-color: transparent;
  justify-content: space-around;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
}
.toggle_button:focus {
  outline: none;
}

.toggle_button_line {
  background-color: #416ce1;
  width: 32px;
  height: 3px;
  border-radius: 5px;
}
