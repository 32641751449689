.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  background: #FFFFFF;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  z-index: 100;
  transition: all 0.3s ease;
}

.navbar.scrolled {
  height: 70px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 24px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logoContainer {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo {
  height: 36px;
  width: auto;
  transition: height 0.3s ease;
}

.scrolled .logo {
  height: 32px;
}

.navLinks {
  display: flex;
  align-items: center;
  gap: 32px;
  margin-left: auto;
  margin-right: 40px;
}

.navLink {
  color: #1E293B;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  padding: 8px 0;
  position: relative;
  transition: all 0.2s ease;
}

.navLink:hover {
  color: #4361EE;
}

.navLink.active {
  color: #4361EE;
  font-weight: 600;
}

.navLink.active::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #4361EE;
  transform: scaleX(1);
  transition: transform 0.2s ease;
}

.solutionsContainer {
  position: relative;
}

.solutionsButton {
  display: flex;
  align-items: center;
  gap: 4px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px 0;
  font-size: 16px;
  font-weight: 500;
  color: #1E293B;
}

.solutionsButton:hover {
  color: #4361EE;
}

.dropdownIcon {
  font-size: 20px;
  transition: transform 0.2s ease;
}

.dropdownIconOpen {
  transform: rotate(180deg);
}

.solutionsDropdown {
  position: absolute;
  top: 100%;
  left: -16px;
  width: 200px;
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 8px 0;
  margin-top: 8px;
  opacity: 0;
  transform: translateY(-10px);
  animation: dropdownFade 0.2s ease forwards;
}

@keyframes dropdownFade {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdownItem {
  display: block;
  padding: 12px 16px;
  color: #1E293B;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.dropdownItem:hover {
  background-color: #F8FAFC;
  color: #4361EE;
}

.dropdownItem.active {
  color: #4361EE;
  font-weight: 600;
  background-color: #F8FAFC;
}

.authButtons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.loginWrapper,
.signupWrapper {
  position: relative;
}

/* Mobile styles */
@media (max-width: 1024px) {
  .container {
    padding: 0 16px;
  }

  .navLinks {
    display: none;
  }

  .authButtons {
    margin-left: auto;
  }
}

Ensure modals appear above navbar
:global(.ReactModal__Overlay) {
  z-index: 999 !important;
}

:global(.ReactModal__Content) {
  z-index: 1000 !important;
}

/* Add these styles to your existing CSS */

.mobileMenuButton {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  color: #1E293B;
  z-index: 1001;
}

.mobileMenuBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.mobileMenu {
  position: fixed;
  top: 0;
  right: -100%;
  width: 80%;
  max-width: 300px;
  height: 100vh;
  background: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  z-index: 1000;
  padding: 20px;
}

.mobileMenu.open {
  right: 0;
}

.mobileNavLinks {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 40px;
}

.mobileSolutionsContainer {
  position: relative;
}

.mobileSolutionsButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  background: none;
  border: none;
  color: #1E293B;
  font-size: 16px;
  cursor: pointer;
}

.mobileSolutionsDropdown {
  padding-left: 20px;
}

.mobileAuthButtons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.mobileAuthButtons button {
  padding: 10px;
  background: none;
  border: 1px solid #4361EE;
  color: #4361EE;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.mobileAuthButtons button:hover {
  background-color: #4361EE;
  color: white;
}

.arrow {
  transition: transform 0.2s ease;
}

.rotated {
  transform: rotate(180deg);
}

@media (max-width: 1024px) {
  .container {
    padding: 0 16px;
  }

  .navLinks {
    display: none;
  }

  .authButtons {
    display: none;
  }

  .mobileMenuButton {
    display: block;
  }
}