.wrapper {
  height: auto;
  padding: 10px 0px;
}

@media (max-width: 1024px) {
  .wrapper {
    overflow: hidden;
    padding: 0;
    background-color: thistle;
  }
}
