@font-face {
  font-family: Lato-semibold;
  src: url("../../../Assets/Fonts/Lato-Semibold.ttf");
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("../../../Assets/Fonts/Lato-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: sfpro;
  src: url("../../../Assets/Fonts/SourceSansPro-Regular.otf");
  font-display: swap;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  max-height: 600px;
  width: auto;
  min-width: 450px;
  background-color: #ffffff;
  border: none;
  overflow-y: auto;
  z-index: 900;
}

.Modal::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 5px;
}

.Modal::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 5px;
}

.Modal::-webkit-scrollbar-thumb {
  background-color: #416ce1;
  border-radius: 10px;
}

.Modal:focus {
  outline: 0;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  -webkit-backdrop-filter: blur(2.3px);
  backdrop-filter: blur(2.3px);
  background-color: #7070702a;
  z-index: 900;
}

.icon {
  padding: 0px 10px 0 0;
  height: 25px;
  cursor: pointer;
}

.text {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.listItem {
  display: flex;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #393939;
}

.cancelBtn {
  background-color: #e4747a;
  color: #fff;
  border: 0px;
  border-radius: 5px;
  font-size: 14px;
  padding: 4px 15px;
  outline: none !important;
  margin: 0 15px;
  width: 150px;
}

.saveBtn {
  background-color: #416ce1;
  color: white;
  border: 0px;
  border-radius: 5px;
  font-size: 14px;
  padding: 4px 15px;
  outline: none !important;
  margin: 0 15px;
  width: 150px;
}

.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  padding: 20px 0px;
}

.closeButton {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 45px;
  width: 45px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.closeButton:focus {
  outline: none;
}

.header {
  flex: 0.7;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 0px;
}

.actualHead {
  font-family: Lato-semibold;
  font-weight: 600;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.56;
}

.tabs {
  width: 100%;
}

/* ******************************************** */

.image {
  flex: 2;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.avatar {
  padding: 5px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #416ce1;
  border-radius: 50px;
}

.mainAvatar {
  height: 300px;
  width: 300px;
}

.actualAvatar {
  height: 70px;
  width: 70px;
}

.imgBtn {
  flex: 0.7;
  width: 50%;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  width: 110px;
  height: 30px;
  border-radius: 5px;
  border: none;
  background-color: #416ce1;
  color: white;
  text-align: center;
  font-family: Lato;
  font-size: 12px;
  outline: none !important;
  transition: all ease-out 0.3s;
}
.btn:focus {
  outline: none;
}

/* ******************************************** */

.main {
  flex: 5;
  /* background-color: coral; */
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.myRow {
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.searchRow {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchDiv {
  width: 95%;
}

.selectBox {
  width: 95%;
  height: 30px;
  border: solid 0.5px #406bdf;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 4px 16px 0 rgba(69, 91, 99, 0.08);
  text-decoration: none !important;
  outline: none;
  padding: 5px;
}

.innerRow {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.actualRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.radio {
  outline: none !important;
  margin-right: 5px;
  cursor: pointer;
}

.genderLabel {
  font-size: 15px;
  font-family: sfpro;
}

.optionVal {
  font-size: 13px;
  font-family: sfpro;
}

.selectBox {
  font-family: sfpro;
}

/* ******************************************** */

@media (max-width: 1025px) {
  .Modal {
    min-width: 450px;
  }
}

@media (max-width: 576px) {
  .Modal {
    width: auto;
    min-width: 350px;
    max-width: 450px;
    height: auto;
    min-height: 550px;
    max-height: 650px;
    overflow-y: scroll;
  }

  .header {
    padding: 0px;
  }

  .actualHead {
    padding: 10px 0px;
  }

  .genderLabel {
    font-size: 13px;
  }

  .btn {
    margin-top: 10px;
  }

  .image {
    margin-top: 5px;
  }
  .closeButton {
    height: 35px;
    width: 35px;
  }
}

@media (max-width: 370px) {
  .Modal {
    max-height: 550px;
  }
}
