.container {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 5fr 5fr 4fr;
  grid-template-rows: 4fr 1fr;
  grid-template-areas: 'assets gap edit' 'text text text';
  height: 100%;
  width: 100%;
}

.assets {
  grid-area: assets;
  max-height: 630px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
}

.gap {
  grid-area: gap;
  max-height: 630px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
}

.edit {
  grid-area: edit;
  display: grid;
  place-items: center;
}

.text {
  grid-area: text;
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}

@media (max-width: 1440px) {
  .container {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 630px 400px 70px;
    grid-template-areas: 'assets gap ' 'edit edit' 'text text';
    height: 100%;
    width: 100%;
  }
  .edit {
    padding: 40px 20px 20px 20px;
  }
  .assets {
    padding: 0px 10px 0px 40px;
  }
  .gap {
    padding: 0px 40px 0px 10px;
  }
}

@media (max-width: 1024px) {
  .container {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 630px 400px 70px;
    grid-template-areas: 'assets gap ' 'edit edit' 'text text';
    height: 100%;
    width: 100%;
  }
}
