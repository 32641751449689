@font-face {
  font-family: SF;
  src: url("../../Assets/Fonts/SourceSansPro-Black.otf");
  font-display: swap;
}

.root {
  width: 95%;
  margin-left: auto;
  /* background-color: pink; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* .mainSlider {
  background-color: aqua;
  height: 25px;
} */

.label {
  margin-bottom: 0;
  font-size: 14px;
  display: flex;
  justify-content: space-between;

  /* background-color: #416ce1; */
  width: 100%;
}

.labelSpacing {
  color: #416ce1;
  white-space: nowrap;
  font-size: 16px;
}

.tp {
  font-family: SF;
  padding: 0px 20px 0px 10px;
}

.main {
  justify-self: center;
  align-self: center;
}

/* .thumb:focus,
:hover {
  box-shadow: inherit;
} */
/* 
.thumb {
  &:focus, &:hover, &$active: {
    box-shadow: inherit;
  }
} */
