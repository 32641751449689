@font-face {
  font-family: sspb;
  src: url("../../../Assets/Fonts/SourceSansPro-Regular.otf");
  font-display: swap;
}

/***********/
.option {
  padding: 5px 10px;
  border: 1px solid #ededed;
  display: flex;
  align-items: center;
}

.option :hover {
  color: #416ce1;
}

.container {
  position: relative;
  font-family: sspb;
  color: #416ce1;
}

.header {
  border-bottom: 2px solid #416ce1;
  padding: 5px 5px;
  font-family: sspb;
  color: #416ce1;
  display: flex;
}

.title {
  flex: 1;
}

.header:hover {
  cursor: pointer;
}

.icon {
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown {
  position: absolute;
  top: 50px;
  right: 0px;
  background-color: rgb(255, 255, 255);
  z-index: 3;
  width: 100px;
  height: 200px;
  /* overflow: auto; */
  border: 1px solid #416ce1;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
}

.item {
  padding: 5px 10px;
  display: flex;
  border-bottom: 1px solid #e5e5e5;
  align-items: center;
  height: 40px;
}

.radio {
  height: 12px;
  width: 12px;
  border-radius: 6px;
  margin-right: 10px;
  border: 1px solid;
}

.item:hover {
  cursor: pointer;
}

.arrowDown {
  font-size: 20px;
}

.arrowUp {
  font-size: 20px;
}
