.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 750px;
  width: 400px;
  background-color: #ffffff;
  border: none;
  overflow-y: auto;
  font-family: Lato;
  z-index: 2;

  min-height: 200px;
  padding: 10px 0px;
}

.Modal:focus {
  outline: 0;
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  -webkit-backdrop-filter: blur(2.3px);
  backdrop-filter: blur(2.3px);
  background-color: #7070702a;
  z-index: 2;
}

.btn {
  width: 180px;
  background-color: #635ae7;
  border-radius: 5px;
  border: none;
  outline: none;
  height: 40px;
  color: white;
}
.btn:focus {
  outline: 0;
}

.outer {
  position: relative;
  height: 100%;
  width: 100%;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  height: 45px;
  width: 45px;
}

.crossBtn {
  height: 3%;
}

.futOuter {
  height: 95%;
}

@media (max-width: 1280px) {
  .Modal {
    max-height: 1050px;
    height: 100vh;
    overflow: scroll;
  }
  .futOuter {
    height: 100%;
    /* background-color: darkolivegreen; */
  }

  .close {
    height: 35px;
    width: 35px;
  }
}

@media (max-width: 576px) {
  .Modal {
    width: 100vw;
    height: 98vh;
    overflow: scroll;
  }
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    height: 35px;
    width: 35px;
  }
}
