.mygraph {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 90%;
}

@media (max-width: 1024px) {
  .mygraph {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .mygraph {
    width: 70%;
  }
}

@media (max-width: 576px) {
  .mygraph {
    width: 90%;
  }
}
