.modal {
  border-radius: 4px;
  border: 0.1px solid #416ce1;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: 31vw;
  min-width: 400px;
  background-color: #ffffff;
  z-index: 2;
  align-items: center;
  justify-content: center;
  padding: 0px 15px 15px 15px;
  max-height: 80%;
  overflow-y: scroll;
  scrollbar-width: 0px;
}

.modal::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 5px;
}

.modal::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 5px;
}

.modal::-webkit-scrollbar-thumb {
  background-color: #416ce1;
  border-radius: 5px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.button {
  align-self: center;
  background-color: #416ce1;
  padding: 10px;
  width: 24vw;
  min-width: 300px;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 0px grey;
}

.button:hover {
  transform: scale(1.02);
}

.inputDiv {
  border: 1px solid #416ce1;
  padding-top: 20px;
  border-radius: 2px;
}

.header {
  font-size: 20px;
  font-weight: 500;
}

.outputDiv {
  border: 1px solid #416ce1;
  padding: 10px 20px 10px 20px;
  border-radius: 2px;
}

.benefitDiv {
  border: 1px solid #416ce1;
  width: 24vw;
  border-radius: 2px;
  display: flex;
  justify-content: center;
}

.benefitDiv > p {
  color: #416ce1;
  font-size: 18px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
}
