.wrapper {
  padding-top: 20px;
}

@font-face {
  font-family: LatoB;
  src: url("../../Assets/Fonts/Lato-Bold.ttf");
  font-display: swap;
}

.left {
  text-align: left;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.23px;
  color: #404a57;
  font-family: LatoB;
  align-self: start;
  padding-left: 10px;
}
