@font-face {
  font-family: LatoBold;
  src: url("../../Assets/Fonts/Lato-Bold.ttf");
}
/* For the Check */
.exclaim {
  width: 30px;
  height: 30px;
  margin: 10px;
}

.row1 {
  height: 500px;

  display: grid;
  place-items: center;
  grid-template-columns: 5fr 8fr;
  grid-template-rows: 1fr;
  grid-template-areas: "fees table";

  grid-gap: 10px;
  padding: 20px;
}

.feesSaved {
  grid-area: fees;
  height: 100%;
  width: 100%;
}

.employerTable {
  grid-area: table;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
}

.seeMore {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.seeMoreCont {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
  position: relative;
}

.picker {
  flex: 1;

  display: flex;
  flex-direction: row;

  display: none;
  padding-bottom: 20px;
}

.tp {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainTable {
  flex: 10;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  background: white;
}

.table {
  flex: 12;
}

/* ******************************************** */

.upper {
  display: flex;
}

.title {
  grid-area: title;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 23px;
  padding: 10px 0px 0px 20px;
  font-family: LatoBold;
}

.graphCont {
  display: flex;
  grid-area: graphCont;
}

.graph1 {
  flex: 1;
}

.graph2 {
  flex: 1;
}

.editA {
  grid-area: editA;
}

.editCard {
  height: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
}

.future {
  grid-area: future;

  background-color: white;
  border-radius: 10px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
}

.futureParent {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.smallFuture {
  height: 100%;
  width: 80%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  display: grid;
  place-items: center;
}

.smallFutureHeading {
  height: 10%;
}

.futureGraph {
  grid-area: futureGraph;
}

.futureTable {
  grid-area: futureTable;
}

.bottomText {
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* *********************************** */

.row3 {
  height: 500px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 10px;
  grid-template-areas: "networth cashflow";
  padding: 20px;
}
.networth {
  grid-area: "networth";
}
.cashflow {
  grid-area: "cashflow";
}

@media (max-width: 1280px) {
  .row1 {
    height: 400px;
    grid-gap: 10px;
    padding: 10px;
  }
  .smallscreen {
    grid-area: smallscreen;
  }

  .futureParent {
    grid-area: futureParent;
  }

  .smallFuture {
    width: 95%;
    height: 600px;
  }

  .feesSaved {
    height: 380px;
  }

  .employerTable {
    height: 380px;
  }

  /* **************************** */

  .row3 {
    height: 400px;
    grid-gap: 10px;
    padding: 10px;
  }

  .networth {
    height: 380px;
  }
  .cashflow {
    height: 380px;
  }
}

@media (max-width: 1100px) {
  .row1 {
    height: 1000px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "fees" "table";
    grid-gap: 30px;
    padding: 10px;
  }

  .feesSaved {
    height: 100%;
    width: 100%;
  }

  .employerTable {
    height: 100%;
  }

  /* **************************** */

  .row3 {
    height: 1050px; /* 1050-60-30 = 960 */
    grid-gap: 10px;
    padding: 10px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 30px;
    grid-template-areas: "networth" "cashflow";
    padding: auto;
  }

  .networth {
    height: 480px;
  }
  .cashflow {
    height: 480px;
  }
}

@media (max-width: 700px) {
  .root {
    max-width: 100%;
    overflow-x: hidden;
  }
  .row1 {
    height: 800px;
  }

  .feesSaved {
    height: 100%;
    width: 100%;
  }

  .employerTable {
    height: 100%;
  }

  /* **************************** */

  .row3 {
    height: 770px;
    grid-gap: 10px;
    padding: 10px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 10px;
    grid-template-areas: "networth" "cashflow";
    padding: auto;
    width: 100%;
  }

  .networth {
    height: 340px;
  }
  .cashflow {
    height: 340px;
  }
  .smallFuture {
    width: 95%;
    height: 400px;
  }
}

@media (max-width: 576px) {
  .picker {
    display: flex;
  }
  .row1 {
    height: 870px;
    grid-template-columns: 1fr;
    grid-template-rows: 350px 490px;
    grid-template-areas: "fees" "table";
    grid-gap: 20px;
    padding: 10px;
  }
}
